import { useEffect } from 'react';
import axios from 'axios';
import store from '../../../store';

const useLocalization = () => {
    useEffect(() => {
        const fetchAllLanguages = async () => {
            const response = await axios.get('/jsl10n/localization/Domstolno');

            const localizationData = response.data;

            store.dispatch({ type: 'textNO', payload: localizationData.no.Domstolno.Resources });
            store.dispatch({ type: 'textEN', payload: localizationData.en.Domstolno.Resources });
            store.dispatch({ type: 'textSE', payload: localizationData.se.Domstolno.Resources });
        };

        fetchAllLanguages();
    }, []);
};

export default useLocalization;
