import {
    Typography,
    Icon,
    SkipToContent,
    LanguageIcon,
    CloseIcon,
    MenuIcon,
    ArrowLeftIcon,
    Button,
} from '@norges-domstoler/dds-components';
import { getLocalizationText, General, TU } from '../../../../../utils/getLocalizationText';
import { LanguageSelector } from '../../LanguageSelector/LanguageSelector';
import { useOnClickOutside } from '@norges-domstoler/dds-components';
import LayoutSettings from 'app/Models/Content/LayoutSettingsData';
import { useEpiserver } from '@episerver/spa-core';
import { useState, useRef } from 'react';
import { State } from '@episerver/spa-core';
import { connect } from 'react-redux';
import './HeaderTilsynsutvalget.scss';
import '../Header.scss';
import {
    norwegian_language_tag_short,
    norwegian_language_full_name,
    samegiella_language_tag_short,
    samegiella_language_full_name,
    english_language_tag_short,
    english_language_full_name,
} from '../../../../../Models/Constants/LanguageTypes';
import TopMenuItems from './TopMenuItems';
import store from '../../../../../store';
import Link from '../../../../../Components/Shared/Link/link';

export type HeaderProps = Partial<State.CmsState> & {
    dispatch?: (action: State.CmsStateAction) => void;
    settings: LayoutSettings;
    path?: string;
};

export const Header = (props: HeaderProps) => {
    const ctx = useEpiserver();

    const handleScrollToMain = () => {
        const main = document.getElementById('main');
        if (main != null) {
            const event = new Event('mainEvent');
            document.body.dispatchEvent(event);
        }
    };

    const [localizationText, setLocalizationText] = useState<string>('');

    store.subscribe(() => {
        setLocalizationText(store.getState().textNO);
    });

    const riksvopenLogoPath = '/StaticContent/logo/tilsynsutvalget_liggende_sort_farget_riksvopen.png';
    const currentLanguage = props?.settings?.language?.name;
    const useLanguageSelector = props?.settings?.useLanguageSelector?.value;

    const [isOpenMenu, setIsOpenMenu] = useState<boolean>(false);
    const [isOpenLanguageSelector, setIsOpenLanguageSelector] = useState<boolean>(false);

    const toggleTopMenuButtons = (
        currentMenuRef: React.MutableRefObject<undefined>,
        toggleIsOpenCurrentMenuItem: React.Dispatch<React.SetStateAction<boolean>>,
        toggleIsOpenOtherMenuItem: React.Dispatch<React.SetStateAction<boolean>>,
        isCurrentMenuItemOpen: boolean,
        isOtherMenuItemOpen: boolean
    ) => {
        if (isCurrentMenuItemOpen && !isOtherMenuItemOpen) {
            toggleIsOpenOtherMenuItem((prevState) => !prevState);
        }

        const nextState = !isCurrentMenuItemOpen;
        toggleIsOpenCurrentMenuItem((prevState) => !prevState);
        toggleIsOpenOtherMenuItem(false);

        if (nextState && currentMenuRef && currentMenuRef.current) (currentMenuRef.current as HTMLInputElement).focus();
    };

    const languageNames: { [id: string]: string } = {
        [norwegian_language_tag_short]: norwegian_language_full_name,
        [samegiella_language_tag_short]: samegiella_language_full_name,
        [english_language_tag_short]: english_language_full_name,
    };

    const languageSelectorRef = useRef(null);
    const menuRef = useRef(null);

    useOnClickOutside(languageSelectorRef.current, () => setIsOpenLanguageSelector(false));
    useOnClickOutside(menuRef.current, () => setIsOpenMenu(false));

    return (
        <>
            <header id="tu-header">
                {!ctx.isServerSideRendering() && (
                    <SkipToContent href="#main" htmlProps={{ onClick: handleScrollToMain }} />
                )}
                <div className="header-top">
                    <div className="logo">
                        <Link href="/">
                            <img
                                alt={getLocalizationText(currentLanguage, [TU, 'LogoAltTekst'])}
                                height="80"
                                src={riksvopenLogoPath}
                            />
                        </Link>
                    </div>
                    <nav>
                        <ul>
                            {useLanguageSelector && (
                                <li ref={languageSelectorRef}>
                                    <Button
                                        id="languageButton"
                                        purpose="tertiary"
                                        className="header-buttons"
                                        aria-expanded={isOpenLanguageSelector}
                                        aria-controls="header-language-box-wrapper"
                                        onClick={() =>
                                            toggleTopMenuButtons(
                                                languageSelectorRef,
                                                setIsOpenLanguageSelector,
                                                setIsOpenMenu,
                                                isOpenLanguageSelector,
                                                isOpenMenu
                                            )
                                        }
                                        icon={isOpenLanguageSelector ? CloseIcon : LanguageIcon}
                                        iconPosition="left"
                                    >
                                        {isOpenLanguageSelector
                                            ? getLocalizationText(currentLanguage, [General, 'Close'])
                                            : languageNames[ctx.Language]}
                                    </Button>
                                    <div
                                        className={`nav-menu-group language ${isOpenLanguageSelector ? 'display' : ''}`}
                                    >
                                        <div className="language-margin">
                                            <div className="menu-icon-button language-selector-button">
                                                <button
                                                    onClick={() => {
                                                        setIsOpenMenu(true);
                                                        setIsOpenLanguageSelector(false);
                                                    }}
                                                >
                                                    <Icon icon={ArrowLeftIcon} iconSize="small" />
                                                    <Typography typographyType="bodySans02">
                                                        {getLocalizationText(currentLanguage, [General, 'Back'])}
                                                    </Typography>
                                                </button>
                                            </div>
                                            <LanguageSelector
                                                useEnglish={props?.settings?.useEnglish?.value}
                                                useSamisk={props?.settings?.useSamisk?.value}
                                                layoutSettingsLanguage={currentLanguage}
                                                isMobile={false}
                                                useLanguageSelector={props.settings?.useLanguageSelector?.value}
                                                isOpenLanguageSelector={isOpenLanguageSelector}
                                                setIsOpenLanguageSelector={(isOpenLanguageSelector: boolean) =>
                                                    setIsOpenLanguageSelector(isOpenLanguageSelector)
                                                }
                                                languageNames={languageNames}
                                                path={props.path}
                                            />
                                        </div>
                                    </div>
                                </li>
                            )}
                            <li ref={menuRef}>
                                <Button
                                    id="menuButton"
                                    purpose="tertiary"
                                    aria-controls="menu"
                                    className="header-buttons"
                                    aria-expanded={isOpenMenu}
                                    onClick={() =>
                                        toggleTopMenuButtons(
                                            menuRef,
                                            setIsOpenMenu,
                                            setIsOpenLanguageSelector,
                                            isOpenMenu,
                                            isOpenLanguageSelector
                                        )
                                    }
                                    icon={isOpenMenu ? CloseIcon : MenuIcon}
                                    iconPosition="left"
                                >
                                    {isOpenMenu
                                        ? getLocalizationText(currentLanguage, [General, 'Close'])
                                        : getLocalizationText(currentLanguage, ['Header', 'MainMenu'])}
                                </Button>
                                <div className={`nav-menu-group ${isOpenMenu ? 'display' : ''}`}>
                                    <TopMenuItems
                                        settings={props.settings}
                                        menuRef={menuRef}
                                        isOpenMenu={isOpenMenu}
                                        setIsOpenMenu={(isMenuOpen) => setIsOpenMenu(isMenuOpen)}
                                        setIsOpenLanguageSelector={setIsOpenLanguageSelector}
                                    />
                                </div>
                            </li>
                        </ul>
                    </nav>
                </div>
            </header>
        </>
    );
};

export const ConnectedHeader = connect((state: State.CmsAppState, otherState: any) => {
    const propsFromState = {
        ...state.OptiContentCloud,
        textNO: otherState.textNO,
    };
    return { ...propsFromState };
})(Header);

export const HeaderTilsynsutvalget = (props: HeaderProps) => {
    const ctx = useEpiserver();
    if (ctx.isServerSideRendering()) return <HeaderTilsynsutvalget {...props} />;
    return <ConnectedHeader {...props} />;
};

export default HeaderTilsynsutvalget;
