import { useEffect } from 'react';
import LayoutSettings from 'app/Models/Content/LayoutSettingsData';
import {
    filterLinkIdFromLinkProperty,
    filterLinkIdsFromLinkListProperty,
} from '../../../utils/layoutSettingsUtils/filterLinkIdsFromLinkListProperty';
import { createLayoutSettingForBannerPayload } from '../../../utils/layoutSettingsUtils/createLayoutSettingForBannerPayload';
import { createLayoutSettingReduxPayload } from '../../../utils/layoutSettingsUtils/createLayoutSettingReduxPayload';
import store from '../../../store';

const useLayoutSettings = (layoutSettings: LayoutSettings) => {
    useEffect(() => {
        if (layoutSettings !== null) {
            const tempMenuIds: string[][] = [];

            filterLinkIdFromLinkProperty(layoutSettings?.findCourtFromHeader, tempMenuIds, 'finnDomstol');
            filterLinkIdsFromLinkListProperty(layoutSettings?.beforeMeetingTheCourtsLinks, tempMenuIds, 'inCourt');
            filterLinkIdsFromLinkListProperty(layoutSettings?.summonedForTrialLinks, tempMenuIds, 'inCourt');
            filterLinkIdsFromLinkListProperty(layoutSettings?.publicAndInsightsLinks, tempMenuIds, 'inCourt');
            filterLinkIdsFromLinkListProperty(layoutSettings?.themeLinks, tempMenuIds, 'servicesAndForms');
            filterLinkIdsFromLinkListProperty(layoutSettings?.servicesLinks, tempMenuIds, 'servicesAndForms');
            filterLinkIdsFromLinkListProperty(layoutSettings?.logInLinks, tempMenuIds, 'servicesAndForms');
            filterLinkIdsFromLinkListProperty(layoutSettings?.courtsInNorwayLinks, tempMenuIds, 'aboutUs');
            filterLinkIdsFromLinkListProperty(layoutSettings?.judgesLinks, tempMenuIds, 'aboutUs');
            filterLinkIdsFromLinkListProperty(layoutSettings?.aboutUsLinks, tempMenuIds, 'aboutUs');

            store.dispatch({ type: 'menuIds', payload: tempMenuIds });
            store.dispatch({ type: 'menuWithFocus', payload: '' });
            store.dispatch({ type: 'hyphensMinWordLength', payload: layoutSettings?.hypphenMinWordLenght?.value });

            store.dispatch({ type: 'layoutSettingModel', payload: createLayoutSettingReduxPayload(layoutSettings) });
            store.dispatch({ type: 'useLanguageSelector', payload: layoutSettings?.useLanguageSelector?.value });
            store.dispatch({ type: 'banner', payload: createLayoutSettingForBannerPayload(layoutSettings) });
        }
    }, [layoutSettings]);
};

export default useLayoutSettings;
