//Import generic libs
import { useEffect, useState, useRef } from 'react';

//Import Episerver Libs
import { SkipToContent, LanguageIcon, SearchIcon, CloseIcon, Button } from '@norges-domstoler/dds-components';
import { State } from '@episerver/spa-core';
import { MenuItem } from '../MenuItem/MenuItem';

//Import App
import LayoutSettings from 'app/Models/Content/LayoutSettingsData';
import HeaderMobile from './HeaderMobile';
import store from '../../../../store';
import './Header.scss';

// Search and Language
import { getLocalizationText, General } from '../../../../utils/getLocalizationText';
import { useEpiserver } from '@episerver/spa-core';
import { connect } from 'react-redux';

import { useWindowDimensions } from '../../../../utils/useWindowDimensions';
import { LanguageSelector } from '../LanguageSelector/LanguageSelector';
import { SearchBoxHeader } from './SearchBoxHeader';
import { navigationMenuSetup } from '../../topMenu';
import '../LanguageSelector/LanguageSelector.scss';
import './SearchBoxHeader.scss';

import {
    nynorsk_language_tag_short,
    norwegian_language_tag_short,
    norwegian_language_full_name,
    samegiella_language_tag_short,
    samegiella_language_full_name,
    english_language_tag_short,
    english_language_full_name,
} from '../../../../Models/Constants/LanguageTypes';
import Link from '../../../Shared/Link/link';

export type HeaderProps = Partial<State.CmsState> & {
    dispatch?: (action: State.CmsStateAction) => void;
    settings: LayoutSettings;
    path?: string;
};

export const HeaderComponent = (props: HeaderProps) => {
    const servicesAndForms = 'servicesAndForms';
    const finnDomstol = 'finnDomstol';
    const aboutUs = 'aboutUs';
    const inCourt = 'inCourt';

    // const mobileMaxWidth = 768;
    const mobileMaxWidth = 1050;

    const ClickOutsideDropdownRef_AboutUs = useRef(null);
    const ClickOutsideDropdownRef_InCourt = useRef(null);
    const ClickOutsideDropdownRef_ServicesAndForms = useRef(null);
    const ClickOutsideSearchDropdownRef = useRef(null);
    const ClickOutsideLanguageDropdownRef = useRef(null);

    const [openMenu, setIsOpen] = useState<string>('');
    const [languageMenuOpen, setLanguageMenuIsOpen] = useState<boolean>(false);
    const { width } = useWindowDimensions();
    const ctx = useEpiserver();

    // MENUWITHFOCUS
    const [activeMenu, setActiveMenu] = useState<string>('');

    // Use this state to re-render the page when Localization language text is loaded
    const [localizationText, setLocalizationText] = useState<string>('');

    store.subscribe(() => {
        setActiveMenu(store.getState().menuWithFocus);
        setLocalizationText(store.getState().textNO);
    });

    const anchorClickHandler = (menu: string) => {
        menu === openMenu ? setIsOpen('') : setIsOpen(menu);
    };

    const handleLanguageChange = () => {
        setActiveMenu('');
        setIsOpen('');
    };

    const handleClickOutside = (event: any) => {
        // check if click on menu items
        const clickedOnMenuTab =
            event.target?.id == servicesAndForms || event.target?.id == aboutUs || event.target?.id == inCourt;
        const clickedOnMenuTabParent =
            clickedOnMenuTab ||
            event.target?.parentElement?.id == servicesAndForms ||
            event.target?.parentElement?.id == aboutUs ||
            event.target?.parentElement?.id == inCourt;

        // check if click on blue submenu box
        const IsNotAboutUs =
            ClickOutsideDropdownRef_AboutUs.current == null
                ? true
                : !ClickOutsideDropdownRef_AboutUs.current.contains(event.target);
        const IsNotServicesAndForms =
            ClickOutsideDropdownRef_ServicesAndForms.current == null
                ? true
                : !ClickOutsideDropdownRef_ServicesAndForms.current.contains(event.target);
        const IsNotInCourt =
            ClickOutsideDropdownRef_InCourt.current == null
                ? true
                : !ClickOutsideDropdownRef_InCourt.current.contains(event.target);

        // if clicked outside the blue submenu, close the menu
        if (
            !clickedOnMenuTabParent &&
            !(
                (IsNotAboutUs && IsNotServicesAndForms && !IsNotInCourt) ||
                (!IsNotAboutUs && IsNotServicesAndForms && IsNotInCourt) ||
                (IsNotAboutUs && !IsNotServicesAndForms && IsNotInCourt)
            )
        ) {
            setIsOpen('');
            setLanguageMenuIsOpen(false);
        }

        const languageButton = document.getElementById('languageButton');
        const searchButton = document.getElementById('searchButton');

        const clickedOnSearchOrLanguageButtons =
            event.composedPath().indexOf(languageButton) == 1 || event.composedPath().indexOf(searchButton) >= 0;
        const notClickOnSearch =
            ClickOutsideSearchDropdownRef.current && !ClickOutsideSearchDropdownRef.current.contains(event.target);
        const notClickedOnLang =
            ClickOutsideLanguageDropdownRef.current && !ClickOutsideLanguageDropdownRef.current.contains(event.target);

        if (!clickedOnSearchOrLanguageButtons && notClickOnSearch && notClickedOnLang) {
            setIsOpenLanguageSelector(false);
            setIsOpenSearch(false);
        }

        if (
            event.target?.classList?.contains('submenu-link') ||
            event.target?.parentElement?.classList.contains('submenu-link') ||
            event.target?.parentElement?.parentElement?.classList.contains('submenu-link')
        ) {
            store.dispatch({ type: 'menuWithFocus', payload: '' });
        }
    };

    useEffect(() => {
        if (languageMenuOpen) {
            setIsOpen('');
        }

        if (!ctx.isServerSideRendering()) document.addEventListener('mousedown', (ev) => handleClickOutside(ev));
    }, [languageMenuOpen]);

    useEffect(() => {
        navigationMenuSetup();
    }, [props.settings]);

    ////////////////////////////////////////////
    ////////  Top menu Search logic ////////////
    ////////////////////////////////////////////

    const searchFieldRef = useRef();
    const languageFieldRef = useRef();

    const searchButtondRef = useRef();
    const languageButtonFieldRef = useRef();

    const [isOpenSearch, setIsOpenSearch] = useState<boolean>(false);
    const [isOpeLanguageSelector, setIsOpenLanguageSelector] = useState<boolean>(false);

    // Toggle dropdown handler for language
    const toggleLanguageMenu = () => {
        if (isOpenSearch && !isOpeLanguageSelector) {
            setIsOpenSearch((prevState) => !prevState);
        }
        const nextState = !isOpeLanguageSelector;
        setIsOpenLanguageSelector((prevState) => !prevState);
        setIsOpen('');

        if (nextState && languageFieldRef && languageFieldRef.current)
            (languageFieldRef.current as HTMLInputElement).focus();
    };

    // Toggle dropdown handler for search
    const toggleSearchMenu = () => {
        if (!isOpenSearch && isOpeLanguageSelector) {
            setIsOpenLanguageSelector((prevState) => !prevState);
        }
        const nextState = !isOpenSearch;
        setIsOpenSearch(nextState);
        setIsOpen('');

        if (nextState && searchFieldRef && searchFieldRef.current) (searchFieldRef.current as HTMLInputElement).focus();
    };

    const languageNames: { [id: string]: string } = {};
    languageNames[norwegian_language_tag_short] = norwegian_language_full_name;
    languageNames[samegiella_language_tag_short] = samegiella_language_full_name;
    languageNames[english_language_tag_short] = english_language_full_name;

    useEffect(() => {
        if (!(openMenu === '')) {
            setIsOpenSearch(false);
            setIsOpenLanguageSelector(false);
        }
    }, [openMenu === '']);

    const handleClick = () => {
        const main = document.getElementById('main');
        if (main != null) {
            main.scrollIntoView({ behavior: 'smooth' });
            const event = new Event('mainEvent');
            document.body.dispatchEvent(event);
        }
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLAnchorElement>) => {
        if (e.key === 'Enter') {
            const main = document.getElementById('main');
            if (main != null) {
                main.scrollIntoView({ behavior: 'smooth' });
                const event = new Event('mainEvent');
                document.body.dispatchEvent(event);
            }
        }
    };

    function adjustFocusAfterSearchAndLanguageSelector() {
        if (isOpeLanguageSelector && languageButtonFieldRef && languageButtonFieldRef.current) {
            setIsOpenLanguageSelector(false);
            (languageButtonFieldRef.current as HTMLInputElement).focus();
        }
        if (isOpenSearch && searchButtondRef && searchButtondRef.current) {
            setIsOpenSearch(false);
            (searchButtondRef.current as HTMLInputElement).focus();
        }
    }

    // if one opens the menu dropdown on mobilscreen, then goes to full screen, we must make sure isMenuOpen is false
    if (width > mobileMaxWidth && store.getState()?.isMenuOpen) {
        store.dispatch({ type: 'isMenuOpen', payload: false });
    }

    const defaultLanguage = norwegian_language_tag_short;

    if (
        !props.settings?.useLanguageSelector?.value &&
        (ctx.Language != defaultLanguage || props.currentLanguage != defaultLanguage)
    ) {
        if (props.dispatch) {
            props.dispatch({ type: 'OptiContentCloud/SetState', currentLanguage: defaultLanguage });
        }
    }

    if (props.settings?.language?.name == nynorsk_language_tag_short) {
        if (props.dispatch) {
            props.dispatch({ type: 'OptiContentCloud/SetState', currentLanguage: defaultLanguage });
        }
    }

    const currentLanguage = props.currentLanguage;

    return (
        <>
            <header key="MoseyHeader" className="domstolno-header">
                {!ctx.isServerSideRendering() && (
                    <SkipToContent
                        href="#main"
                        htmlProps={{ onClick: handleClick, onKeyDown: (e) => handleKeyDown(e) }}
                    />
                )}
                {(width > mobileMaxWidth || ctx.isServerSideRendering()) && (
                    <div className="header-top">
                        <div
                            id="header-top"
                            className={`row header-first-row-border-line ${languageMenuOpen ? '' : 'header-first-row'}`}
                        >
                            <div
                                className={(width < 1200 ? 'small-screen col-3 ' : ' col-12 ') + 'col-lg header-image'}
                            >
                                <Link href="/" className="d-block">
                                    <img
                                        className={
                                            'header-image-adjust' +
                                            (props.settings?.language?.name == samegiella_language_tag_short
                                                ? ' samisk'
                                                : '')
                                        }
                                        alt={getLocalizationText(currentLanguage, ['Header', 'Logo'])}
                                        width="200"
                                        height="107"
                                        src={
                                            '/StaticContent/logo/horisontallogo_' +
                                            (props.settings?.language?.name ? props.settings?.language?.name : 'no') +
                                            '.svg'
                                        }
                                    />
                                </Link>
                            </div>

                            <nav
                                id="mobile-header-menu"
                                aria-label={getLocalizationText(currentLanguage, ['Header', 'MainMenu'])}
                                className={
                                    'nav-section ' +
                                    (props.settings?.language?.name == samegiella_language_tag_short
                                        ? 'col-9'
                                        : 'col-8')
                                }
                            >
                                <ul className="menu-margin">
                                    {props?.settings?.useLanguageSelector?.value && (
                                        <li>
                                            <Button
                                                id="languageButton"
                                                purpose="tertiary"
                                                className="header-buttons-language-and-search icon-background"
                                                aria-expanded={isOpeLanguageSelector}
                                                ref={languageButtonFieldRef}
                                                aria-controls="header-language-box-wrapper"
                                                onClick={() => toggleLanguageMenu()}
                                                icon={isOpeLanguageSelector ? CloseIcon : LanguageIcon}
                                                iconPosition="right"
                                            >
                                                {isOpeLanguageSelector
                                                    ? getLocalizationText(currentLanguage, [General, 'Close'])
                                                    : languageNames[ctx.Language]}
                                            </Button>
                                        </li>
                                    )}
                                    {props.settings?.searchPageUrl?.value && (
                                        <li>
                                            <Button
                                                id="searchButton"
                                                ref={searchButtondRef}
                                                purpose="tertiary"
                                                className="search-box-header-button header-buttons-language-and-search icon-background"
                                                aria-expanded={isOpenSearch}
                                                aria-controls="header-search-box-wrapper"
                                                onClick={() => toggleSearchMenu()}
                                                icon={isOpenSearch ? CloseIcon : SearchIcon}
                                                iconPosition="right"
                                            >
                                                {isOpenSearch
                                                    ? getLocalizationText(currentLanguage, [General, 'Close'])
                                                    : getLocalizationText(currentLanguage, [General, 'Search'])}
                                            </Button>
                                        </li>
                                    )}
                                </ul>

                                <ul
                                    id="menubar-domstol"
                                    role="menubar"
                                    className={
                                        props.settings?.language?.name == samegiella_language_tag_short
                                            ? 'samisk-menu'
                                            : ''
                                    }
                                >
                                    <MenuItem
                                        {...props}
                                        childPageVisited={activeMenu == finnDomstol && openMenu == ''}
                                        menuItemHeading={props.settings?.findCourtFromHeader?.value?.text}
                                        href={
                                            props.settings?.findCourtFromHeader?.value?.contentLink?.url ??
                                            props.settings?.findCourtFromHeader?.value?.href
                                        }
                                        showDropDown={false}
                                        listProp={null}
                                    />

                                    <MenuItem
                                        {...props}
                                        clickedOnLink={() => handleLanguageChange()}
                                        menuItemHeading={props.settings?.inCourtHeading?.value}
                                        clickOnMenuItem={() => anchorClickHandler(inCourt)}
                                        childPageVisited={activeMenu == inCourt && openMenu == ''}
                                        showDropDown={openMenu === inCourt}
                                        selected={openMenu === inCourt}
                                        menuItemRef={ClickOutsideDropdownRef_InCourt}
                                        id={inCourt}
                                        listProp={[
                                            {
                                                linksHeading: props.settings?.beforeMeetingTheCourtsHeading?.value,
                                                links: props.settings?.beforeMeetingTheCourtsLinks,
                                            },
                                            {
                                                linksHeading: props.settings?.summonedForTrialHeading?.value,
                                                links: props.settings?.summonedForTrialLinks,
                                            },
                                            {
                                                linksHeading: props.settings?.publicAndInsightsHeading?.value,
                                                links: props.settings?.publicAndInsightsLinks,
                                            },
                                        ]}
                                    />

                                    <MenuItem
                                        {...props}
                                        clickedOnLink={() => handleLanguageChange()}
                                        menuItemHeading={props.settings?.servicesAndFormsHeading?.value}
                                        clickOnMenuItem={() => anchorClickHandler(servicesAndForms)}
                                        childPageVisited={activeMenu == servicesAndForms && openMenu == ''}
                                        showDropDown={openMenu === servicesAndForms}
                                        selected={openMenu === servicesAndForms}
                                        menuItemRef={ClickOutsideDropdownRef_ServicesAndForms}
                                        id={servicesAndForms}
                                        listProp={[
                                            {
                                                linksHeading: props.settings?.themeHeading?.value,
                                                links: props.settings?.themeLinks,
                                            },
                                            {
                                                linksHeading: props.settings?.servicesHeading?.value,
                                                links: props.settings?.servicesLinks,
                                            },
                                            {
                                                linksHeading: props.settings?.logInHeading?.value,
                                                links: props.settings?.logInLinks,
                                            },
                                        ]}
                                    />

                                    <MenuItem
                                        {...props}
                                        clickedOnLink={() => handleLanguageChange()}
                                        menuItemHeading={props.settings?.aboutHeading?.value}
                                        clickOnMenuItem={() => anchorClickHandler(aboutUs)}
                                        childPageVisited={activeMenu == aboutUs && openMenu == ''}
                                        showDropDown={openMenu === aboutUs}
                                        selected={openMenu === aboutUs}
                                        menuItemRef={ClickOutsideDropdownRef_AboutUs}
                                        id={aboutUs}
                                        listProp={[
                                            {
                                                linksHeading: props.settings?.courtsInNorwayHeading?.value,
                                                links: props.settings?.courtsInNorwayLinks,
                                            },
                                            {
                                                linksHeading: props.settings?.judgesHeading?.value,
                                                links: props.settings?.judgesLinks,
                                            },
                                            {
                                                linksHeading: props.settings?.aboutUsHeading?.value,
                                                links: props.settings?.aboutUsLinks,
                                            },
                                        ]}
                                    />
                                </ul>
                            </nav>
                        </div>

                        {/* Language and Search dropdown menu */}
                        <div
                            className={
                                'absolute-drop-down-menu header-general-submenu' +
                                (isOpenSearch ? '' : ' visually-hidden')
                            }
                            ref={ClickOutsideSearchDropdownRef}
                        >
                            <div id="header-search-box-wrapper" className="search-box-header-row sub-menu-min-height">
                                <div className="search-box-margin">
                                    <div className="container py-5">
                                        <div className="col-12 col-lg-7 row">
                                            <SearchBoxHeader
                                                searchFieldRef={searchFieldRef}
                                                isOpenSearch={isOpenSearch}
                                                isMobile={false}
                                                setIsOpenSearch={(isOpenSearch: boolean) =>
                                                    setIsOpenSearch(isOpenSearch)
                                                }
                                                searchPath={
                                                    props.settings?.searchPageUrl?.value
                                                        ? props.settings.searchPageUrl.value?.href
                                                        : '#'
                                                }
                                                language={props.currentLanguage}
                                                path={props.path}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div
                            ref={ClickOutsideLanguageDropdownRef}
                            className={
                                'absolute-drop-down-menu header-general-submenu language-selector-row sub-menu-min-height row' +
                                (isOpeLanguageSelector ? '' : ' visually-hidden')
                            }
                        >
                            <div className="language-margin">
                                <LanguageSelector
                                    useEnglish={props?.settings?.useEnglish?.value}
                                    useSamisk={props?.settings?.useSamisk?.value}
                                    layoutSettingsLanguage={props.settings?.language?.name}
                                    isMobile={false}
                                    useLanguageSelector={props.settings?.useLanguageSelector?.value}
                                    isOpenLanguageSelector={isOpeLanguageSelector}
                                    setIsOpenLanguageSelector={(isOpenLanguageSelector: boolean) =>
                                        setIsOpenLanguageSelector(isOpenLanguageSelector)
                                    }
                                    isMenuOpen={() => handleLanguageChange()}
                                    languageNames={languageNames}
                                    languageFieldRef={languageFieldRef}
                                    path={props.path}
                                />
                            </div>
                        </div>

                        <div
                            onFocus={() => {
                                adjustFocusAfterSearchAndLanguageSelector();
                            }}
                            tabIndex={isOpeLanguageSelector || isOpenSearch ? 0 : -1}
                            className={isOpeLanguageSelector || isOpenSearch ? '' : ' visually-hidden'}
                        ></div>
                    </div>
                )}
                {width <= mobileMaxWidth && (
                    <HeaderMobile
                        setIsOpenLanguageSelector={(isOpenLanguageSelector: boolean) =>
                            setIsOpenLanguageSelector(isOpenLanguageSelector)
                        }
                        screenwidth={width}
                        settings={props.settings}
                        language={
                            props.settings?.language?.name ? props.settings.language.name : norwegian_language_tag_short
                        }
                        searchPath={
                            props.settings?.searchPageUrl?.value ? props.settings.searchPageUrl.value?.href : '#'
                        }
                        path={props.path}
                        languageNames={languageNames}
                        isMenuOpen={() => handleLanguageChange()}
                        useLanguageSelector={props?.settings?.useLanguageSelector?.value}
                    />
                )}
            </header>
        </>
    );
};
export const ConnectedHeader = connect((state: State.CmsAppState) => state.OptiContentCloud || {})(HeaderComponent);

export const Header = (props: HeaderProps) => {
    const ctx = useEpiserver();
    if (ctx.isServerSideRendering()) return <HeaderComponent {...props} />;
    return <ConnectedHeader {...props} />;
};

export default Header;
