import { State, Taxonomy, useEpiserver, useIContentRepository } from '@episerver/spa-core';
import { useState, useEffect } from 'react';
import { useSettings } from '../../packages/foundation-settings';
import { useLocation } from 'react-router';
import { connect } from 'react-redux';
import { toCamel } from '../../utils/camelCase/convertObjectTocamelCase';
import DefaultLayout from './DefaultLayout';
import OldLayout, { CurrentContent } from '../Shared/Old/Layout/OldLayout';

import LayoutSettings from 'app/Models/Content/LayoutSettingsData';
import Website from '@episerver/spa-core/dist/Models/Website';
import { Helmet } from 'react-helmet-async';
import axios from 'axios';
import { MoseyLayoutProps } from './MoseyLayoutProps';
import useLocalization from './Hooks/useLocalization';
import useLayoutSettings from './Hooks/useLayoutSettings';

const LayoutSettingsContainer: string = 'LayoutSettings';
const MoseyLayout = (props: MoseyLayoutProps) => {
    const repo = useIContentRepository();
    const settingsService = useSettings();
    const ctx = useEpiserver();

    const [layoutSettings, setLayoutSettings] = useState<LayoutSettings | undefined>();
    const [currentContent, setCurrentContent] = useState<Taxonomy.IContent | undefined>();

    const language = props.currentLanguage;

    const location = useLocation();
    const pathName = location.pathname;

    useEffect(() => {
        if (layoutSettings?.language?.name !== language) {
            repo.getCurrentWebsite().then((site) => {
                if (!site) {
                    axios.get('/api/episerver/v3.0/site').then((response) => {
                        settingsService
                            .getContainer<LayoutSettings>(LayoutSettingsContainer, response.data[0] as Website)
                            .then((x) => setLayoutSettings(toCamel(x) as LayoutSettings));
                    });
                } else {
                    settingsService
                        .getContainer<LayoutSettings>(LayoutSettingsContainer, site)
                        .then((x) => setLayoutSettings(toCamel(x) as LayoutSettings));
                }
            });
        }
    }, [language, layoutSettings?.language?.name, repo, settingsService]);

    useLocalization();

    useLayoutSettings(layoutSettings);

    useEffect(() => {
        if (pathName) {
            repo.getByRoute(pathName).then((iContent) => {
                setCurrentContent(iContent);
            });
        }
    }, [pathName, repo]);

    if (!currentContent && !ctx.isServerSideRendering()) {
        return (
            <div className="mosey-layout">
                <Helmet>
                    <title>Norges domstoler</title>
                </Helmet>
            </div>
        );
    }

    if (
        currentContent &&
        (currentContent.contentType.includes('OldDomainPage') ||
            currentContent.contentType.includes('OldBerammingSokPage') ||
            currentContent.contentType.includes('ArticlePage') ||
            currentContent.contentType.includes('DummyPage') ||
            currentContent.contentType.includes('SiteMapPage') ||
            currentContent.contentType.includes('TagPage') ||
            currentContent.contentType.includes('OldSearchPage') ||
            currentContent.contentType.includes('TransportPage'))
    ) {
        const loadedCurrentContet = currentContent as CurrentContent;

        return (
            <>
                <Helmet>
                    <meta property="og:url" content={window.location.href} />
                </Helmet>
                <OldLayout
                    key="oldlayout"
                    {...props}
                    language={language}
                    currentContent={loadedCurrentContet}
                    layoutSettings={layoutSettings?.feedbackDisclaimer}
                    pathname={pathName}
                />
            </>
        );
    }

    return (
        <>
            <Helmet>
                <meta property="og:url" content={window.location.href} />
            </Helmet>
            <DefaultLayout
                key="default"
                {...props}
                language={language}
                layoutSettings={layoutSettings}
                pathname={pathName}
            />
        </>
    );
};

export const ConnectedMoseyLayout = connect((state: State.CmsAppState, ownProps: MoseyLayoutProps) => {
    const propsFromState = state.OptiContentCloud || {};
    return { ...ownProps, ...propsFromState };
})(MoseyLayout);

export default ConnectedMoseyLayout;
